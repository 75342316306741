import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './App.css';

import {
  Login,
  CreateBlog,
  Blogs,
  EditBlog,
  SeoContents,
  EditSeoContent,
  CreateSeoContent
} from './pages';

import { MainLayout } from './layouts';
import { setSelectedLanguage } from './redux/userSlice';
import ScrollToTop from './helpers/ScrollToTop';

import { LANGUAGES, LANGUAGE_ROUTES } from './constants';

function App() {
  const dispatch = useDispatch();

  const { i18n } = useTranslation('translation');
  const { selectedLanguage } = useSelector((s) => s.user);

  if (selectedLanguage?.code !== i18n.language) i18n.changeLanguage(selectedLanguage?.code);

  useEffect(() => {
    const urlLanguage = window?.location?.pathname?.split('/')[1];

    const includedLanguage = LANGUAGES.find((l) => l.code === urlLanguage);
    const isIncluded = !!includedLanguage;
    const isSelected = includedLanguage?.code !== selectedLanguage?.code;

    if (isIncluded && isSelected) {
      dispatch(setSelectedLanguage(LANGUAGES.find((l) => l.code === urlLanguage)));
    }
  }, [dispatch, selectedLanguage]);

  return (
    <Router>
      {LANGUAGE_ROUTES.map((route) => (
        <ScrollToTop key={route}>
          <div className="App">
            <Routes>

              <Route path={`${route}/login`} element={<Login />} />

              <Route path={route} element={<MainLayout />}>
                <Route path="blogs" element={<Blogs />} />
                <Route path="blogs/:id" element={<EditBlog />} />
                <Route path="create-blog" element={<CreateBlog />} />
                <Route path="seo-contents" element={<SeoContents />} />
                <Route path="seo-contents/:id" element={<EditSeoContent />} />
                <Route path="create-seo-content" element={<CreateSeoContent />} />
              </Route>
            </Routes>
          </div>
        </ScrollToTop>
      ))}
    </Router>
  );
}

export default App;
