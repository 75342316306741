import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchMe } from '../../redux/userSlice';

function Auth({ children }) {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.user);

  useEffect(() => {
    const token = localStorage.getItem('blogger-token');
    if (token && status.auth === 'idle') {
      dispatch(fetchMe());
    }
  }, [dispatch, status.auth]);

  return children;
}

export default Auth;
