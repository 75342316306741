import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Blog from '../../components/Blog/Blog';
import { fetchBlogs } from '../../redux/blogSlice';

import './Blogs.scss';

function Blogs() {
  const { Option } = Select;
  const dispatch = useDispatch();

  const { t } = useTranslation('translation');
  const { blogs, writers } = useSelector((state) => state.blogs);
  const { user, status: userStatus } = useSelector((state) => state.user);

  const [sortedBlogs, setSortedBlogs] = useState([]);
  const [filter, setFilter] = useState({});

  const blogSort = (a, b) => {
    const dateA = moment(a?.createdAt);
    const dateB = moment(b?.createdAt);
    return dateB.diff(dateA);
  };

  useEffect(() => {
    setSortedBlogs(blogs?.slice()?.sort(blogSort));
  }, [blogs]);

  useEffect(() => {
    if (userStatus.auth === 'succeeded') {
      dispatch(fetchBlogs({ role: user?.role, filter }));
    }
  }, [dispatch, userStatus, user?.role, filter]);

  return (
    <div className="blogs-container">
      <div className="blogs-header">
        <div className="title">
          { user?.role === 'BloggerAdmin' ? t('my_blogs.all_blogs') : t('my_blogs.my_blogs')}
        </div>
        { user?.role === 'BloggerAdmin' && (
          <div className="filters">
            <div className="filter">
              <span>{t('editor.blog_writer')}</span>
              <Select
                value={filter?.writer || ''}
                onChange={(e) => {
                  if (!e) {
                    const newFilter = JSON.parse(JSON.stringify(filter));
                    delete newFilter?.writer;
                    setFilter(newFilter);
                    return;
                  }

                  setFilter((f) => ({ ...f, writer: e }));
                }}
                style={{ width: '150px' }}
              >
                <Option value="">{t('editor.all')}</Option>
                {writers?.map((writer) => (
                  <Option value={writer?._id}>{writer?.full_name}</Option>
                ))}
              </Select>
            </div>

            <div className="filter">
              <span>{t('editor.publish_status')}</span>
              <Select
                value={filter?.published || ''}
                onChange={(e) => {
                  if (!e) {
                    const newFilter = JSON.parse(JSON.stringify(filter));
                    delete newFilter?.published;
                    setFilter(newFilter);
                    return;
                  }

                  setFilter((f) => ({ ...f, published: e }));
                }}
                style={{ width: '150px' }}
              >
                <Option value="">{t('editor.all')}</Option>
                <Option value="true">{t('editor.published')}</Option>
                <Option value="false">{t('editor.not_published')}</Option>
              </Select>
            </div>
          </div>
        ) }
      </div>
      <div className="blogs">
        {
            (sortedBlogs?.length) ? sortedBlogs?.map((blog) => (<Blog blog={blog} />)) : null
        }
      </div>
    </div>
  );
}

export default Blogs;
