import { configureStore } from '@reduxjs/toolkit';

import blogSlice from './blogSlice';
import userSlice from './userSlice';
import categorySlice from './categorySlice';
import seoContentSlice from './seoContentSlice';

export default configureStore({
  reducer: {
    blogs: blogSlice,
    user: userSlice,
    category: categorySlice,
    seoContents: seoContentSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});
