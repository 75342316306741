import React, { useEffect } from 'react';
import {
  Link, Navigate, Outlet, useLocation,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import {
  ReadOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { logOut } from '../../redux/userSlice';
import { fetchBlogs, fetchWriters } from '../../redux/blogSlice';

import './MainLayout.scss';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';

function MainLayout() {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const dispatch = useDispatch();

  const { status: userStatus, languagePath, user } = useSelector((state) => state.user);
  const { status: blogStatus } = useSelector((state) => state.blogs);

  useEffect(() => {
    if (userStatus.auth === 'succeeded') {
      dispatch(fetchBlogs({ role: user?.role }));
    }
  }, [dispatch, userStatus, user?.role]);

  useEffect(() => {
    if (userStatus.auth === 'succeeded' && blogStatus.fetchWriters === 'idle') {
      dispatch(fetchWriters());
    }
  }, [dispatch, userStatus, blogStatus.fetchWriters]);

  if (userStatus.auth !== 'succeeded' && !localStorage.getItem('blogger-token')) {
    return <Navigate to={`${languagePath}/login?path=${window.location.pathname}`} />;
  }

  const isSelected = (path) => location.pathname === path;

  return (
    <div className="layout">
      <div className="layout-aside">
        <div className="logo">LONCA</div>
        <div className="link-container">
          <Link to="/blogs" className={`link ${isSelected('/blogs') ? 'selected' : ''}`}>
            <ReadOutlined />
            <span>{ user?.role === 'BloggerAdmin' ? t('main_layout.all_blogs') : t('main_layout.my_blogs') }</span>
          </Link>
          <Link to="/create-blog" className={`link ${isSelected('/create-blog') ? 'selected' : ''}`}>
            <EditOutlined />
            <span>{t('main_layout.create_new')}</span>
          </Link>

          {(user?.role === 'BloggerAdmin' || user?.role === 'SeoContentEditor') && 
            <>
              <Link to="/seo-contents" className={`link ${isSelected('/seo-contents') ? 'selected' : ''}`}>
                <ReadOutlined />
                <span>{t('main_layout.all_seo_contents')}</span>
              </Link>
              <Link to="/create-seo-content" className={`link ${isSelected('/create-seo-content') ? 'selected' : ''}`}>
                <EditOutlined />
                <span>{t('main_layout.create_new_seo_content')}</span>
              </Link>
            </>
          }
        </div>
      </div>
      <div className="main-layout">
        <div className="layout-nav">
          <div className="header">{t('main_layout.portal')}</div>
          <div className="buttons">
            <div className="selector"><LanguageSelector /></div>
            <div className="logout"><Button onClick={() => dispatch(logOut())}>{t('main_layout.logout')}</Button></div>
          </div>
        </div>
        <div className="layout-section">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
