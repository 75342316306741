import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchSeoContents = createAsyncThunk('seo-content/get', async ({ filter }) => {
  const { data } = await axios.post(`${url}/seo-content/all-seo-contents/`, filter);
  return data;
});

export const fetchUploadUrl = (key) => axios.get(`${url}/seo-content/s3Url/`, { params: { key } });

export const createSeoContent = (payload) => axios.post(`${url}/seo-content/`, payload);

export const fetchSeoContentById = (id) => axios.get(`${url}/seo-content/fetch/${id}`);

export const updateSeoContent = (id, update) => axios.patch(`${url}/seo-content/`, { id, update });

export const publishSeoContent = (id) => axios.patch(`${url}/seo-content/publish`, { id });

export const deleteSeoContent = (id) => axios.delete(`${url}/seo-content/${id}`);

const seoContent = createSlice({
  name: 'seoContents',
  initialState: {
    seoContents: [],
    status: {
      fetchSeoContents: 'idle',
    },
    error: null,
  },
  extraReducers: {
    // fetch seo content
    [fetchSeoContents.pending]: (state) => {
      state.status.fetchSeoContents = 'pending';
    },
    [fetchSeoContents.fulfilled]: (state, action) => {
      state.status.fetchSeoContents = 'succeeded';
      state.seoContents = action.payload;
    },
    [fetchSeoContents.rejected]: (state, action) => {
      state.status.fetchSeoContents = 'rejected';
      state.error = action.payload;
    },

  },
});

export default seoContent.reducer;
