import { Quill } from 'react-quill';

const Link = Quill.import('formats/link');

class StructuredLink extends Link {
  static create(value) {
    let valueParam = value;
    const node = super.create(valueParam);
    valueParam = this.sanitize(valueParam);
    node.setAttribute('href', valueParam);
    if (!value.startsWith('https://lonca.co')) {
      node.setAttribute('rel', 'noopener noreferrer nofollow');
    }
    return node;
  }
}

export default StructuredLink;
