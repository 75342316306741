import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, notification, Modal } from 'antd';
import {
  fetchUploadUrl, fetchBlogById,
  updateBlog, fetchBlogs, publishBlog, deleteBlog,
} from '../../redux/blogSlice';

import useInterval from '../../hooks/useInterval';

import Editor from '../../components/Editor/Editor';

import './EditBlog.scss';

function EditBlog() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = useParams()?.id;

  const { user } = useSelector((state) => state.user);
  const { blogs } = useSelector((state) => state.blogs);

  const [text, setText] = useState();
  const [title, setTitle] = useState();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [blog, setBlog] = useState({});

  const [initialBlog, setInitialBlog] = useState();
  const [imageChanged, setImageChanged] = useState(false);

  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [mainKeywords, setMainKeywords] = useState('');
  const [semanticKeywords, setSemanticKeywords] = useState('');
  const [link, setLink] = useState('');
  const [note, setNote] = useState('');
  const [assignee, setAssignee] = useState('');
  const [language, setLanguage] = useState('');
  const [isShownInHomepage, setIsShownInHomepage] = useState('false');
  const [publishedAt, setPublishedAt] = useState();
  const [categories, setCategories] = useState();

  const [imageUploading, setImageUploading] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);

  const handleMainImageUpload = async () => {
    const response = await fetchUploadUrl(`blog-images/${user?.full_name?.replaceAll(' ', '') || 'anonymous'}/${blogs?.link?.slice(5) || new Date()?.getTime()?.toString()}/main_image`);
    await fetch(response.data, {
      method: 'PUT',
      body: fileList[0]?.originFileObj,
    });

    const imageUrl = response.data.split('?')[0];
    return imageUrl;
  };

  const createSubmitPayload = async () => {
    const mainImage = imageChanged ? await handleMainImageUpload() : null;

    const update = { };

    if (!mainImage && mainImage !== null) {
      setLoading(false);
      notification.error({
        message: t('errors.main_image_save_error'),
      });
      return;
    } if (mainImage) {
      update.main_image = mainImage;
    }

    if (title !== initialBlog?.title) update.title = title;
    if (text !== initialBlog?.text) update.text = text;
    if (link !== initialBlog?.link) update.link = link;
    if (metaTitle !== initialBlog?.metaTitle) update.metaTitle = metaTitle;
    if (metaDescription !== initialBlog?.metaDescription) update.metaDescription = metaDescription;
    if (mainKeywords !== initialBlog?.mainKeywords) update.mainKeywords = mainKeywords;
    if (semanticKeywords !== initialBlog?.semanticKeywords) {
      update.semanticKeywords = semanticKeywords;
    }
    if (note !== initialBlog?.note) update.note = note;
    if (assignee !== initialBlog?.writer) update.writer = assignee;
    if (language !== initialBlog?.language) update.language = language;
    if (isShownInHomepage !== initialBlog?.isShownInHomepage) update.isShownInHomepage = isShownInHomepage;
    if (publishedAt !== initialBlog?.publishedAt) update.publishedAt = publishedAt;

    update.categories = categories;

    return update;
  };

  useInterval(async () => {
    if (publishLoading || deleteLoading || imageUploading) return;

    try {
      setLoading(true);
      const update = await createSubmitPayload();

      if (update?.writer === '') {
        setLoading(false);
        return;
      }

      updateBlog(id, update)
        .then(() => {
          setLoading(false);
          setImageChanged(false);
        })
        .catch(() => {
          setLoading(false);
          setImageChanged(false);
        });
    } catch (err) {
      console.error('Save Error:', err);
    }
  }, 60000, true);

  useEffect(() => {
    fetchBlogById(id)
      .then((res) => {
        setBlog(res?.data);
        setText(res?.data?.text);
        setInitialBlog(res?.data);
        setTitle(res?.data?.title);
        setFileList([{ thumbUrl: res?.data?.main_image, url: res?.data?.main_image, status: 'done' }]);
        setLink(res?.data?.link);
        setMetaTitle(res?.data?.metaTitle);
        setMetaDescription(res?.data?.metaDescription);
        setMainKeywords(res?.data?.mainKeywords);
        setSemanticKeywords(res?.data?.semanticKeywords);
        setNote(res?.data?.note);
        setLanguage(res?.data?.language || 'tr');
        setIsShownInHomepage(res?.data?.isShownInHomepage || 'false');
        setPublishedAt(res?.data?.publishedAt || new Date());
        setCategories(res?.data?.categories || new Date());

        if (user?._id?.toString() !== res?.data?._id?.toString()) {
          setAssignee(res?.data?.writer?.toString());
        }
      })
      .catch((err) => console.error(err));
  }, [id, refresh, user?._id]);

  const handleCancel = () => {
    navigate('/blogs');
  };

  const handleSubmit = async () => {
    setLoading(true);

    const update = await createSubmitPayload();

    updateBlog(id, update)
      .then(() => {
        setLoading(false);
        notification.success({
          message: t('errors.blog_save_success'),
        });
        dispatch(fetchBlogs({ role: user?.role }));
        setRefresh((r) => !r);
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: t('errors.blog_save_error'),
        });
        setRefresh((r) => !r);
      });
  };

  const handlePublish = () => {
    setPublishLoading(true);

    publishBlog(id)
      .then(() => {
        setPublishLoading(false);
        notification.success({
          message: t('errors.blog_save_success'),
        });
        dispatch(fetchBlogs({ role: user?.role }));
        setRefresh((r) => !r);
      })
      .catch(() => {
        setPublishLoading(false);
        notification.error({
          message: t('errors.blog_save_error'),
        });
        setRefresh((r) => !r);
      });
  };

  const handleDelete = () => {
    setDeleteLoading(true);

    deleteBlog(id)
      .then(() => {
        setDeleteLoading(false);
        notification.success({
          message: t('errors.blog_delete_success'),
        });
        dispatch(fetchBlogs({ role: user?.role }));
        navigate('/blogs');
      })
      .catch(() => {
        setDeleteLoading(false);
        notification.error({
          message: t('errors.blog_delete_error'),
        });
      });
  };

  return (
    <div className="create-blog-container">
      <Modal
        title={t('editor.delete_warning_title')}
        visible={deleteWarningModal}
        onCancel={() => setDeleteWarningModal(false)}
        footer={[
          <>
            <Button onClick={() => {
              setDeleteWarningModal(false);
              handleDelete();
            }}
            >
              {t('buttons.delete')}
            </Button>
            <Button onClick={() => setDeleteWarningModal(false)}>{t('buttons.cancel')}</Button>
          </>,
        ]}
      >
        <p>{t('editor.delete_warning')}</p>
      </Modal>
      <div className="edit-save-button">
        <div className="left">
          <Button type="secondary" onClick={handleCancel}>{t('buttons.cancel')}</Button>
        </div>
        <div className="right">
          <Button type="secondary" onClick={handleSubmit} loading={loading}>{t('buttons.save')}</Button>
          { user?.role === 'BloggerAdmin' ? (
            <>
              <Button loading={publishLoading} type="secondary" onClick={handlePublish}>{ blog?.published ? t('buttons.unpublish') : t('buttons.publish')}</Button>
              <Button loading={deleteLoading} className="delete-button" type="secondary" onClick={() => setDeleteWarningModal(true)}>{t('buttons.delete')}</Button>
            </>
          )
            : null}
        </div>
      </div>
      <div className="editor-container">
        <div>
          <Editor
            text={text}
            setText={setText}
            title={title}
            setTitle={setTitle}
            fileList={fileList}
            setFileList={setFileList}
            setImageChanged={setImageChanged}
            type="edit"
            metaDescription={metaDescription}
            setMetaDescription={setMetaDescription}
            metaTitle={metaTitle}
            setMetaTitle={setMetaTitle}
            link={link}
            setLink={setLink}
            semanticKeywords={semanticKeywords}
            setSemanticKeywords={setSemanticKeywords}
            mainKeywords={mainKeywords}
            setMainKeywords={setMainKeywords}
            note={note}
            setNote={setNote}
            assignee={assignee}
            setAssignee={setAssignee}
            language={language}
            setLanguage={setLanguage}
            isShownInHomepage={isShownInHomepage}
            setIsShownInHomepage={setIsShownInHomepage}
            publishedAt={publishedAt}
            setPublishedAt={setPublishedAt}
            setImageUploading={setImageUploading}
            categories={categories}
            setCategories={setCategories}
          />
        </div>
      </div>
    </div>
  );
}

export default EditBlog;
