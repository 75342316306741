import React from 'react';
import { useNavigate } from 'react-router-dom';

import './SeoContent.scss';

function SeoContent({ seoContent }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/seo-contents/${seoContent?._id}`);
  };

  return (
    <div className="seo-content-container" onClick={handleNavigate} role="menuitem" onKeyUp={handleNavigate} tabIndex={0}>
      <div className="seo-content-title">{seoContent?.title}</div>
      <div className="seo-content-text">
        <p>{seoContent?.link}</p>
      </div>
    </div>
  );
}

export default SeoContent;
