import React, { useEffect, useMemo, useState, useRef } from 'react';
import ReactDomServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import { Button, Input } from 'antd';
import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from 'quill-image-uploader';
import StructuredLink from '../../helpers/LinkChecker';

import { fetchUploadUrl } from '../../redux/seoContentSlice';
import { ALL_LANGUAGES } from '../../constants';

import './SeoContentEditor.scss';

Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);
Quill.register(StructuredLink);

const icons = Quill.import('ui/icons');
icons.undo = `${ReactDomServer.renderToString(<UndoOutlined style={{ fontSize: '16px' }} />)}`;
icons.redo = `${ReactDomServer.renderToString(<RedoOutlined style={{ fontSize: '16px' }} />)}`;

const maxRows = 10;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r += 1) {
  for (let c = 1; c <= maxCols; c += 1) {
    tableOptions.push(`newtable_${r}_${c}`);
  }
}

const toolbar = [
  ['bold', 'italic', 'underline'], // toggled buttons
  ['blockquote'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ header: [1, 2, 3, 4, false] }],

  [{
    color: ['black', 'silver', 'gray', 'white', 'maroon', 'red', 'purple', 'fuchsia', 'green', 'lime', 'olive', 'yellow', 'navy', 'blue', 'teal', 'aqua'],
  }],

  ['link'],

  ['image'],

  ['undo'],
  ['redo']
];

const BlockEmbed = Quill.import('blots/block/embed');

class TableBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }
}

TableBlot.blotName = 'table';
TableBlot.tagName = 'table';

// Register the new blot with Quill
Quill.register(TableBlot);

function SeoContentEditor({
  texts,
  setTexts,
  title,
  setTitle,
  titles,
  setTitles,
  metaTitles,
  setMetaTitles,
  type,
  link,
  setLink,
}) {
  const { t } = useTranslation('translation');
  const quillRef = useRef();

  const { user } = useSelector((state) => state.user);
  const { seoContents } = useSelector((state) => state.seoContents);

  const [imageNode, setImageNode] = useState();
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [isLoading, setIsLoading] = useState(false);
  const [isHtmlView, setIsHtmlView] = useState(false);

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;

      const quillObj = quillRef?.current?.getEditor();
      const range = quillObj?.getSelection();

      if (file) {
        const response = await fetchUploadUrl(`seocontent-images/${user?.full_name || 'anonymous'}/${seoContents?.length || 0}/${new Date().getTime()}`);
        await fetch(response.data, {
          method: 'PUT',
          body: file,
        });

        const imageUrl = response.data.split('?')[0];

        quillObj.editor.insertEmbed(range.index, 'image', imageUrl);
        quillObj.setContents(quillObj.getContents());
      }
    };
  };

  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    const editorToolbar = editor?.getModule('toolbar');
    editorToolbar?.addHandler('image', () => {
      imageHandler();
    });
  }, [seoContents?.length, user?.full_name]);

  useEffect(() => {
    const ImageBlot = Quill.import('formats/image');
    const Parchment = Quill.import('parchment');

    const quillObj = quillRef?.current?.getEditor();

    quillObj?.root?.addEventListener('click', (event) => {
      const image = Parchment.find(event.target);

      if (image instanceof ImageBlot) {
        setImageNode(image);

        quillObj.setSelection(image.offset(quillObj.scroll), 1, 'user');

        const altImage = image?.domNode?.getAttribute('alt') || '';

        const altEditor = document.getElementsByClassName('alt-editor')[0];
        altEditor.style.left = `${event.clientX}px`;
        altEditor.style.top = `${event.clientY}px`;
        altEditor.style.display = 'block';

        const altInput = document.getElementsByClassName('alt-input')[0];
        altInput.value = altImage;
      } else {
        const altEditor = document.getElementsByClassName('alt-editor')[0];
        altEditor.style.display = 'none';
      }
    });
  }, []);

  const undoHandler = () => {
    const editorObj = quillRef?.current?.getEditor();
    return editorObj.history.undo();
  };

  const redoHandler = () => {
    const editorObj = quillRef?.current?.getEditor();
    return editorObj.history.redo();
  };

  const modules = useMemo(() => ({
    table: true,
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: toolbar,
      handlers: {
        image: imageHandler,
        undo: undoHandler,
        redo: redoHandler,
      },
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
      parchment: Quill.import('parchment'),
    },
    clipboard: {
      matchVisual: false,
    },
  }), []);

  const handleLanguageChange = (lang) => {
    setIsLoading(true);
    setCurrentLanguage(lang);
  };

  useEffect(() => {
    const storedTexts = JSON.parse(window.localStorage.getItem('newSeoContentFields'))?.texts || {};
    if (storedTexts[currentLanguage]) {
      setTexts(prevTexts => ({
        ...prevTexts,
        [currentLanguage]: storedTexts[currentLanguage]
      }));
    }

    setIsLoading(false);

  }, [currentLanguage]);

  const setContent = (newValue) => {
    setTexts(prevTexts => {
      const updatedTexts = { ...prevTexts, [currentLanguage]: newValue || "" };

      if (type === 'create') {
        const localStorageValues = JSON.parse(window.localStorage.getItem('newSeoContentFields')) || {};
        window.localStorage.setItem('newSeoContentFields', JSON.stringify({
          ...localStorageValues,
          texts: updatedTexts,
        }));
      }

      return updatedTexts;
    });
  }

  const setTitleContents = (newValue) => {
    setTitles(prevTitles => {
      const updatedTitles = { ...prevTitles, [currentLanguage]: newValue || "" };

      if (type === 'create') {
        const localStorageValues = JSON.parse(window.localStorage.getItem('newSeoContentFields')) || {};
        window.localStorage.setItem('newSeoContentFields', JSON.stringify({
          ...localStorageValues,
          titles: updatedTitles,
        }));
      }

      return updatedTitles;
    });
  }

  const setMetaTitleContents = (newValue) => {
    setMetaTitles(prevMetaTitles => {
      const updatedMetaTitles = { ...prevMetaTitles, [currentLanguage]: newValue || "" };

      if (type === 'create') {
        const localStorageValues = JSON.parse(window.localStorage.getItem('newSeoContentFields')) || {};
        window.localStorage.setItem('newSeoContentFields', JSON.stringify({
          ...localStorageValues,
          metaTitles: updatedMetaTitles,
        }));
      }

      return updatedMetaTitles;
    });
  }

  return (
    <>
      <div>
        <div className="seo-content-field">
          <span className="label">
            {t('editor.title')}
            :
          </span>
          <Input.TextArea
            className="field"
            placeholder={t('editor.title_placeholder_seo')}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              if (type === 'create') {
                window.localStorage.setItem('newSeoContentFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newSeoContentFields')),
                  title: e.target.value || '',
                }));
              }
            }}
          />
        </div>

        <div className="seo-content-field">
          <span className="label">
            {t('editor.link')}
            :
          </span>
          <Input.TextArea
            className="field"
            placeholder="category/..."
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
              if (type === 'create') {
                window.localStorage.setItem('newSeoContentFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newSeoContentFields')),
                  link: e.target.value || '',
                }));
              }
            }}
          />
        </div>

        <div className="language-tabs">
          {ALL_LANGUAGES.map((lang) => (
            <button 
              key={lang} 
              onClick={() => handleLanguageChange(lang)}
              className={`language-tab ${currentLanguage === lang ? 'active' : ''}`}
            >
              {lang?.toUpperCase()}
            </button>
          ))}
        </div>

        <div className="seo-content-field">
          <span className="label">
            {t('editor.header')}
            :
          </span>
          <Input.TextArea
            className="field"
            value={titles?.[currentLanguage]}
            onChange={((e) => {
              setTitleContents(e.target.value);
            })}
          />
        </div>

        <div className="seo-content-field">
          <span className="label">
            {t('editor.slogan')}
            :
          </span>
          <Input.TextArea
            className="field"
            value={metaTitles?.[currentLanguage]}
            onChange={((e) => {
              setMetaTitleContents(e.target.value);
            })}
          />
        </div>

        <Button onClick={() => setIsHtmlView(v => !v)}>
          {isHtmlView ? 'Switch to Rendered View' : 'Switch to HTML View'}
        </Button>

        {(!isLoading && isHtmlView) && 
          <textarea
            value={texts?.[currentLanguage]}
            onChange={((e) => {
              setContent(e.target.value);
            })}
            style={{ width: '100%', height: '300px', marginTop: "2rem", fontSize: "0.8rem" }}
          />
        }

        {(!isLoading && !isHtmlView) &&
          <ReactQuill
            theme="snow"
            ref={quillRef}
            modules={modules}
            // formats={formats}
            value={texts[currentLanguage]}
            onChange={((html) => {
              setContent(html);
            })}
            placeholder={t('editor.text_placeholder_seo')}
          />
        }   
        

        <div className="alt-editor">
          <div>
            Alt Text:
            {' '}
            <input className="alt-input" type="textarea" />
            <button
              type="button"
              onClick={() => {
                const altInput = document.getElementsByClassName('alt-input')[0];
                imageNode?.domNode?.setAttribute('alt', altInput.value);

                const altEditor = document.getElementsByClassName('alt-editor')[0];
                altEditor.style.display = 'none';
              }}
            >
              {t('buttons.save')}
            </button>
            <button
              type="button"
              onClick={() => {
                const altEditor = document.getElementsByClassName('alt-editor')[0];
                altEditor.style.display = 'none';
              }}
            >
              {t('buttons.cancel')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeoContentEditor;
