const EDITOR_FORMAT = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'imageBlot',
  'alt',
  'height',
  'width',
  'style',
  'size',
];

const EDITOR_TOOLBAR = [
  ['bold', 'italic', 'underline'], // toggled buttons
  ['blockquote'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ header: [1, 2, 3, false, 4] }],

  ['link', 'image'],
];

const LANGUAGE_ROUTES = ['/gb', '/'];
const LANGUAGES = [
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'gb',
    name: 'English',
  },
];

const ALL_LANGUAGES = ['en', 'tr', 'fr', 'ar', 'ro', 'ru', 'gr', 'rs', 'de', 'bg', 'lt', 'es', 'pt', 'it', 'sl', 'pl', 'nl', 'sk', 'cs', 'hr'];


module.exports = {
  EDITOR_FORMAT,
  EDITOR_TOOLBAR,
  LANGUAGE_ROUTES,
  LANGUAGES,
  ALL_LANGUAGES
};
