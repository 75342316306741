import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { fetchBlogs, fetchUploadUrl, createBlog } from '../../redux/blogSlice';

import Editor from '../../components/Editor/Editor';

import './CreateBlog.scss';

function CreateBlog() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { blogs } = useSelector((state) => state.blogs);

  const [text, setText] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.body);
  const [title, setTitle] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.title);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [metaTitle, setMetaTitle] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.metaTitle);
  const [metaDescription, setMetaDescription] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.metaDescription);
  const [mainKeywords, setMainKeywords] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.mainKeyword);
  const [semanticKeywords, setSemanticKeywords] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.semanticKeywords);
  const [link, setLink] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.link);
  const [note, setNote] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.note);
  const [assignee, setAssignee] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.assignee);
  const [language, setLanguage] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.language);
  const [isShownInHomepage, setIsShownInHomepage] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.isShownInHomepage);

  const [publishedAt, setPublishedAt] = useState(JSON.parse(window.localStorage.getItem('newBlogFields'))?.publishedAt);
  const [categories, setCategories] = useState([]);

  const handleMainImageUpload = async () => {
    const response = await fetchUploadUrl(`blog-images/${user?.full_name?.replaceAll(' ', '') || 'anonymous'}/${blogs?.link?.slice(5) || new Date()?.getTime()?.toString()}/main_image`);
    await fetch(response.data, {
      method: 'PUT',
      body: fileList[0]?.originFileObj,
    });

    const imageUrl = response.data.split('?')[0];
    return imageUrl;
  };

  const handleSubmit = async () => {
    setLoading(true);

    const mainImage = await handleMainImageUpload();

    if (!mainImage) {
      setLoading(false);
      notification.error({
        message: t('errors.main_image_save_error'),
      });
      return;
    }

    const payload = {
      title,
      text,
      mainImage,
      link,
      metaTitle,
      metaDescription,
      mainKeywords,
      semanticKeywords,
      note,
      language,
      publishedAt,
      categories,
      isShownInHomepage,
    };

    if (user?.role === 'Blogger') payload.writer = user?._id?.toString();

    createBlog(payload)
      .then(() => {
        setLoading(false);
        notification.success({
          message: t('errors.blog_save_success'),
        });
        window.localStorage.removeItem('newBlogFields');
        dispatch(fetchBlogs({ role: user?.role }));
        navigate('/blogs');
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: t('errors.blog_save_error'),
        });
      });
  };

  return (
    <div className="create-blog-container">
      <div className="save-button">
        <Button type="primary" onClick={handleSubmit} loading={loading}>{t('buttons.save')}</Button>
      </div>
      <div className="editor-container">
        <div>
          <Editor
            text={text}
            setText={setText}
            title={title}
            setTitle={setTitle}
            fileList={fileList}
            setFileList={setFileList}
            type="create"
            metaDescription={metaDescription}
            setMetaDescription={setMetaDescription}
            metaTitle={metaTitle}
            setMetaTitle={setMetaTitle}
            link={link}
            setLink={setLink}
            semanticKeywords={semanticKeywords}
            setSemanticKeywords={setSemanticKeywords}
            mainKeywords={mainKeywords}
            setMainKeywords={setMainKeywords}
            note={note}
            setNote={setNote}
            assignee={assignee}
            setAssignee={setAssignee}
            language={language}
            setLanguage={setLanguage}
            isShownInHomepage={isShownInHomepage}
            setIsShownInHomepage={setIsShownInHomepage}
            publishedAt={publishedAt}
            setPublishedAt={setPublishedAt}
            categories={categories}
            setCategories={setCategories}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateBlog;
