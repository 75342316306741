import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SeoContent from '../../components/SeoContent/SeoContent';
import { fetchSeoContents } from '../../redux/seoContentSlice';

import './SeoContents.scss';

function SeoContents() {
  const { Option } = Select;
  const dispatch = useDispatch();

  const { t } = useTranslation('translation');
  const { seoContents } = useSelector((state) => state.seoContents);
  const { user, status: userStatus } = useSelector((state) => state.user);

  const [sortedContents, setSortedContents] = useState([]);
  const [filter, setFilter] = useState({});

  const contentSort = (a, b) => {
    const dateA = moment(a?.createdAt);
    const dateB = moment(b?.createdAt);
    return dateB.diff(dateA);
  };

  useEffect(() => {
    setSortedContents(seoContents?.slice()?.sort(contentSort));
  }, [seoContents]);

  useEffect(() => {
    if (userStatus.auth === 'succeeded') {
      dispatch(fetchSeoContents({ filter }));
    }
  }, [dispatch, userStatus, filter]);

  return (
    <div className="seo-contents-container">
      <div className="seo-contents-header">
        <div className="title">
          {t('my_blogs.all_seo_contents')}
        </div>
          <div className="filters">
            <div className="filter">
              <span>{t('editor.publish_status')}</span>
              <Select
                value={filter?.published || ''}
                onChange={(e) => {
                  if (!e) {
                    const newFilter = JSON.parse(JSON.stringify(filter));
                    delete newFilter?.published;
                    setFilter(newFilter);
                    return;
                  }

                  setFilter((f) => ({ ...f, published: e }));
                }}
                style={{ width: '150px' }}
              >
                <Option value="">{t('editor.all')}</Option>
                <Option value="true">{t('editor.published')}</Option>
                <Option value="false">{t('editor.not_published')}</Option>
              </Select>
            </div>
          </div>
        
      </div>
      <div className="seo-contents">
        {
            (sortedContents?.length) ? sortedContents?.map((seoContent) => (<SeoContent seoContent={seoContent} />)) : null
        }
      </div>
    </div>
  );
}

export default SeoContents;
