import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';

import './i18n';

import './index.css';
import store from './redux/store';

import { Auth } from './layouts';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Auth>
      <App />
    </Auth>
  </Provider>,
);
