import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchBlogs = createAsyncThunk('blog/get', async ({ role, filter }) => {
  if (role === 'BloggerAdmin') {
    const { data } = await axios.post(`${url}/blog/all-blogs/`, filter);
    return data;
  }
  const { data } = await axios.get(`${url}/blog/writer-blogs/`);
  return data;
});

export const fetchBlogById = (id) => axios.get(`${url}/blog/fetch/${id}`);

export const fetchUploadUrl = (key) => axios.get(`${url}/blog/s3Url/`, { params: { key } });

export const createBlog = (payload) => axios.post(`${url}/blog/`, payload);

export const updateBlog = (id, update) => axios.patch(`${url}/blog/`, { id, update });

export const publishBlog = (id) => axios.patch(`${url}/blog/publish`, { id });

export const deleteBlog = (id) => axios.delete(`${url}/blog/${id}`);

export const fetchWriters = createAsyncThunk('blog/writers', async () => {
  const { data } = await axios.get(`${url}/blog/writers`);
  return data;
});

const userSlice = createSlice({
  name: 'blog',
  initialState: {
    blogs: [],
    writers: [],
    status: {
      fetchBlogs: 'idle',
      fetchWriters: 'idle',
    },
    error: null,
  },
  extraReducers: {
    // fetch blogs
    [fetchBlogs.pending]: (state) => {
      state.status.fetchBlogs = 'pending';
    },
    [fetchBlogs.fulfilled]: (state, action) => {
      state.status.fetchBlogs = 'succeeded';
      state.blogs = action.payload;
    },
    [fetchBlogs.rejected]: (state, action) => {
      state.status.fetchBlogs = 'rejected';
      state.error = action.payload;
    },

    // fetch blog writers
    [fetchWriters.pending]: (state) => {
      state.status.fetchWriters = 'pending';
    },
    [fetchWriters.fulfilled]: (state, action) => {
      state.status.fetchWriters = 'succeeded';
      state.writers = action.payload;
    },
    [fetchWriters.rejected]: (state, action) => {
      state.status.fetchWriters = 'rejected';
      state.error = action.payload;
    },
  },
});

export default userSlice.reducer;
