import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const { data } = await axios.get(`${url}/collection/category/all`);
    return data;
  },
);

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    error: null,
  },
  extraReducers: {
    [fetchCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export default categorySlice.reducer;
