import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setSelectedLanguage, setUser } from '../../redux/userSlice';

import './LanguageSelector.scss';

import { LANGUAGES } from '../../constants';

function LanguageSelector() {
  const { t } = useTranslation('translation');
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    selectedLanguage: selected,
    status: userStatus,
    user,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (userStatus.auth === 'succeeded' && user.selected_language && user.selected_language !== selected?.code) {
      const code = user.selected_language === 'tr' ? '' : user.selected_language;
      const url = location?.pathname?.split('/').slice(selected?.code === 'tr' ? 1 : 2).join('/'); // url part after /rs/
      dispatch(setSelectedLanguage(LANGUAGES.find((l) => l.code === user.selected_language)));
      navigate(url ? `../${code}/${url}` : `../${code}`);
    }
  }, [navigate, dispatch, userStatus.auth]);

  return (
    <div className="language-selector">
      <Select
        className="selector"
        value={LANGUAGES.find((l) => l?.code === selected?.code)?.code}
        onChange={(e) => {
          const code = e === 'tr' ? '' : e;
          const url = location?.pathname?.split('/').slice(selected?.code === 'tr' ? 1 : 2).join('/'); // url part after /rs/

          if (userStatus.auth === 'succeeded') {
            dispatch(setUser({ ...user, selected_language: e }));
            dispatch(setSelectedLanguage(LANGUAGES.find((l) => l?.code === e)));
            navigate(url ? `../${code}/${url}` : `../${code}`);
          } else {
            dispatch(setSelectedLanguage(LANGUAGES.find((l) => l?.code === e)));
            navigate(url ? `../${code}/${url}` : `../${code}`);
          }
        }}
      >
        {LANGUAGES?.map((language) => (
          <Option key={language?.code} value={language?.code} className="option">
            {t(`languages.${language.name}`)}
            {' '}
            <img className="flag" height="12" width="16" alt={selected?.code} src={`https://flagcdn.com/16x12/${language?.code}.png`} />
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default LanguageSelector;
