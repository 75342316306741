const convertString = (phrase) => {
  const maxLength = 100;
  let str = phrase.toLowerCase();
  const charMap = {
    ö: 'o',
    ç: 'c',
    ş: 's',
    ı: 'i',
    ğ: 'g',
    ü: 'u',
  };

  const rx = /(ö|ç|ş|ı|ğ|ü)/g;

  // if any non-english charr exists,replace it with proper char
  if (rx.test(str)) {
    str = str.replace(rx, (_, key) => charMap[key]);
  }

  // if there are other invalid chars, convert them into blank spaces
  str = str.replace(/[^a-z\d\s-_]/gi, '');
  // convert multiple spaces and hyphens into one space
  str = str.replace(/[\s-]+/g, ' ');
  // trim string
  str.replace(/^\s+|\s+$/g, '');
  // cut string
  str = str.substring(0, str.length <= maxLength ? str.length : maxLength);
  // add hyphens
  str = str.replace(/\s/g, '-');

  return str;
};

module.exports = {
  convertString,
};
