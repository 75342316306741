import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  Form, Input, Button, notification,
} from 'antd';

import { fetchLogin } from '../../redux/userSlice';

import './Login.scss';

function Login() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { status, languagePath } = useSelector((state) => state.user);

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const onFinish = async (values) => {
    try {
      await dispatch(fetchLogin(values)).unwrap();
    } catch (error) {
      notification.error({
        message: error.error,
      });
    }
  };

  useEffect(() => {
    if (status.auth === 'succeeded') {
      navigate(query.get('path') ? `${languagePath}${query.get('path')}` : `${languagePath}/blogs`);
    }
  }, [navigate, status, query, languagePath]);

  return (
    <div className="login-page">
      <div className="logo">LONCA</div>
      <div className="login-container">
        <h2 className="login-welcome">{t('login.welcome')}</h2>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('login.email_warning'),
              },
              {
                pattern:
                            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                message: t('login.invalid_email'),
              },
            ]}
          >
            <Input
              disabled={status.fetchLogin === 'pending'}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('login.email')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('login.password_warning'),
              },
            ]}
          >
            <Input
              disabled={status.fetchLogin === 'pending'}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('login.password')}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn-primary">
              {t('login.login')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
